/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";
import colors from 'vuetify/lib/util/colors'


const vuetify = new Vuetify({
  theme: {
  themes:  { 
    light: {
    primary: colors.green.darken1, // #E53935
    secondary: colors.green.lighten4, // #FFCDD2
    accent: colors.indigo.base,
    buttonc: colors.green.lighten9, // #3F51B5
    dark: {
      primary: colors.blue.lighten3, 
      background: colors.indigo.base,
    }
  }}},
  icons: {
    iconfont: "fa",
  },

});

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
});
