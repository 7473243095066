<template>
  <v-app>
    <drawer :drawer="drawer"></drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <v-container fluid>
        <div
          class="
            min-height-200
            border-radius-xl
            overflow-hidden
            position-relative
            mt-6
          "
          :style="`background-image: url(${require('../../assets/img/curved-images/asd.jpg')}); background-position: 50%; background-size: cover;`"
        >
          <span class="mask bg-gradient-success opacity-3"></span>
          <app-bar-profile
            background="bg-transparent"
            has-bg
            @drawer-toggle="drawer = $event"
            :toggle-active="drawer"
          >
          </app-bar-profile>
        </div>
        <v-card
          class="
            px-4
            py-4
            mx-6
            overflow-hidden
            border-radius-xl
            blur
            shadow-blur
            mt-n16
          "
        >
          <v-row>
            <v-col cols="auto">
              <v-avatar width="50" height="50">
                <img src="@/assets/img/user.png" alt="Avatar" />
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-h6 text-typo font-weight-bold">
                  Welcome, {{ username }}
                </h5>
                <v-col>
                  <v-row>
                    <p class="mb-0 font-weight-bold text-body text-sm">
                      <v-btn
                        x-small
                        dark
                        color="primary"
                        outlined
                        rounded
                        @click="logout"
                        >Logout</v-btn
                      >
                      &nbsp;
                      <v-btn
                        x-small
                        dark
                        color="primary"
                        outlined
                        rounded
                        @click="changePassword"
                        >Change Password
                      </v-btn>
                    </p>
                  </v-row>
                </v-col>
              </div>
            </v-col>

            <v-col
              lg="6"
              md="8"
              class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
            >
              <div class="d-flex align-center justify-end spacing-2">
                <v-btn
                  href="#/pages/dashboards/default"
                  left
                  dark
                  outlined
                  medium
                  color="primary"
                  rounded
                >
                  <v-icon left> mdi-home </v-icon>Home
                </v-btn>
                &nbsp;
                <v-btn
                  href="#/pages/pages/profile/message"
                  left
                  dark
                  outlined
                  medium
                  color="primary"
                  rounded
                >
                  <v-icon left> mdi-brain </v-icon>AI
                </v-btn>
                &nbsp;
                <v-btn
                  href="#/pages/pages/profile/setting"
                  left
                  dark
                  outlined
                  medium
                  color="primary"
                  rounded
                >
                  <v-icon left> mdi-tune </v-icon>Settings
                </v-btn>
                &nbsp;
                <v-btn
                  v-if="userDetails.user_role !== 'User'"
                  href="#/pages/pages/profile/user"
                  left
                  dark
                  outlined
                  medium
                  color="primary"
                  rounded
                >
                  <v-icon left> mdi-account-plus </v-icon>Users
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </v-main>
  </v-app>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBarProfile from "@/components/AppBarProfile.vue";
import ContentFooter from "@/components/Footer.vue";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBarProfile,
  },
  data() {
    return {
      drawer: null,
      userDetails: "",
    };
  },
  computed: {
    username() {
      return `${this.userDetails.firstname} ${this.userDetails.lastname}`;
    },
  },
  beforeMount() {
    if (localStorage.getItem("token") == null) {
      this.$router.push("/pages/authentication/signin/signin");
    }
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    updatePassword(inp1, inp2) {
      const token = localStorage.getItem("token");
      const newPassword = {
        oldpassword: inp1,
        newpassword: inp2,
      };
      
      this.$axios
        .patch(
          "api/user/change-password/" + this.userDetails._id,
          newPassword,
          {
            headers: {
              token: token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(
          (res) => {
            this.$swal({
              title: `Password changed successfully`,
            });
          },
          (err) => {
            this.$swal({
              title: err.response.data.error,
            });
            this.error = err.response.data.error;
          }
        );
    },
    changePassword() {
      this.$swal({
        title: "Change password",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: false,
        confirmButtonText: "Change",
        showLoaderOnConfirm: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        html:
          '<input placeholder="Enter old password" id="swal-input1" class="swal2-input">' +
          '<input placeholder="Enter new password" id="swal-input2" class="swal2-input">',
      }).then((result) => {
        const inp1 = document.getElementById("swal-input1").value;
        const inp2 = document.getElementById("swal-input2").value;

        if (result.isConfirmed) {
          this.updatePassword(inp1, inp2);
        }
      });
    },
    logout() {
      localStorage.clear();
      this.$router.push("/pages/authentication/signin/signin");
    },
    fetchProfile() {
      this.$axios
        .get("/api/user/me", {
          headers: { token: localStorage.getItem("token") },
        })
        .then(({ data }) => {
          this.userDetails = data.user;
          if (data.user.isinvite) {
            this.changePassword();
          }
        });
    },
  },
  mounted() {
    this.fetchProfile();
    this.initScrollbar();
  },
};
</script>
