import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueSweetalert2 from "vue-sweetalert2";
import ToggleButton from "vue-js-toggle-button";
import axiosInstance from "./axios.interceptor";

Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";

import store from "./store";
Vue.use(Photoswipe);

Vue.prototype.$axios = axiosInstance;

// plugin setup
Vue.use(ToggleButton);
Vue.use(DashboardPlugin);
Vue.use(VueSweetalert2);
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
