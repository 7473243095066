import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";

// Dashboard pages
const SmartHome = () => import("../views/Dashboard/Realtime.vue");
const Factory = () => import(/* webpackChunkName: "pages" */ "@/views/Dashboard/Factory.vue");
const Farm = () => import(/* webpackChunkName: "pages" */ "@/views/Dashboard/farm.vue");
const Alarms = () => import("../views/Pages/Alarms/Alarms.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");

const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );

const Msg = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileMessage.vue"
  );

const ProfileSetting = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileSetting.vue"
  );
const ProfileUser = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileUser.vue"
  );
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const SignUpCover = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Cover.vue"
  );
const SignIn = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignIn/SignIn.vue"
  );

// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce

const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/ProductPage.vue"
  );

Vue.use(VueRouter);

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/setting",
      name: "ProfileSetting",
      component: ProfileSetting,
      meta: {
        groupName: "",
      },
    },
    {
      path: "/pages/pages/profile/user",
      name: "ProfileUser",
      component: ProfileUser,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/message",
      name: "ProfileMessage",
      component: Msg,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/crops",
      name: "Crops",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/pages/pages/account/settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Predictive Maintenance",
      component: Billing,
      meta: {
        groupName: "",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let applicationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Application",
  children: [
    {
      path: "/pages/applications/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};

let pricingPage = {
  path: "/",
  component: PageLayout,
  name: "Pricing Page",
  children: [
    {
      path: "/pages/pages/pricing-page",
      name: "Pricing",
      component: Pricing,
    },
  ],
};

let authCoverPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Cover",
  children: [
    {
      path: "/pages/authentication/signup/cover",
      name: "SignUpCover",
      component: SignUpCover,
    },
    {
      path: "/pages/authentication/signin/signin",
      name: "SignIn",
      component: SignIn,
    },
  ],
};

let Default = {
  path: "/",
  component: ProfileLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/dashboards/default",
      name: "Main",
      component: ProfileOverview,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/pages/dashboards/default",
    component: DashboardLayout,
    children: [
      {
        path: "/pages/dashboards/real-time",
        name: "Energy Management",
        component: SmartHome,
        meta: {
          groupName: "",
        },
      },
      {
        path: "/pages/dashboards/factory",
        name: "Factory",
        component: Factory,
        meta: {
          groupName: "",
        },
      },
      {
        path: "/pages/dashboards/factory/:id",
        name: "Factory",
        component: Farm,
        meta: {
          groupName: "",
        },
      },
      {
        path: "/pages/dashboards/control",
        name: "Control",
        component: Crm,
        meta: {
          groupName: "",
        },
      },
      {
        path: "/pages/pages/almanac",
        name: "Almanac",
        component: Charts,
        meta: {
          groupName: "",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/iothub",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },

      {
        path: "/shop",
        name: "ProductPage",
        component: ProductPage,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/alert",
        name: "Notification",
        component: Alarms,
        meta: {
          groupName: "",
        },
      },
    ],
  },
  pricingPage,
  profilePages,
  applicationPages,
  userPages,
  accountPages,
  projectsPages,
  authCoverPages,
  Default,
];

const router = new VueRouter({
  routes,
});

export default router;
