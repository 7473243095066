<template>
  <v-navigation-drawer
    width="100%"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-lg"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <v-img
              src="@/assets/img/logo-ct.png"
              class="navbar-brand-img ms-3"
              width="175"
            >
            </v-img>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr class="horizontal dark mb-4" />

    <v-list shaped>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="(item, i) in items"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-icon size="20 " class="mx-2 align-left">
            {{ item.icon }}
          </v-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="ms-1">
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-card
      class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
      :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span class="mask opacity-8 border-radius-lg bg-gradient-success"></span>
      <div class="px-4 py-4 position-relative">
        <v-avatar
          color="bg-white"
          class="shadow"
          width="32"
          height="32"
          min-width="32"
          rounded
        >
          <v-icon size="20" class="text-gradient-info"> ni ni-books </v-icon>
        </v-avatar>
        <div class="docs-info">
          <v-card-text class="text-white text-h6 ps-0 pb-0"
            >Yardım mı Lazım?</v-card-text
          >
          <v-card-subtitle class="text-white text-xs font-weight-bold pt-0 ps-0"
            >Lütfen dökümanları kontrol edin!</v-card-subtitle
          >
          <v-btn
            elevation="0"
            :ripple="false"
            small
            class="
              font-weight-bolder
              text-uppercase
              btn-white
              py-4
              px-6
              rounded-md
              me-2
              my-2
              w-100
            "
            href="#/pages/pages/alerts"
            color="#fff"
            >Dökümanlar
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      selectedItem: 1,
      mini: false,

      items: [
        {
          title: "Home",
          icon: "mdi-home",
          prefix: "M",
          link: "/pages/dashboards/default",
          active: "true",
        },
        {
          title: "Energy",
          icon: "mdi-lightning-bolt-outline",
          prefix: "D",
          link: "/pages/dashboards/real-time",
        },
        {
          title: "Factory",
          icon: "mdi-factory",
          prefix: "F",
          link: "/pages/dashboards/factory",
        },

        {
          title: "Control",
          icon: "mdi-toggle-switch-off-outline",
          prefix: "F",
          link: "/pages/dashboards/control",
        },
        {
          title: "Traceablity",
          icon: "mdi-qrcode",
          prefix: "T",
          link: "/pages/crops",
        },
        {
          title: "Almanac",
          icon: "mdi-history",
          prefix: "G",
          link: "/pages/pages/almanac",
        },
        {
          title: "Alarms",
          icon: "mdi-bell-outline",
          prefix: "G",
          link: "/alert",
        },

        {
          title: "Maintenance",
          icon: "mdi-tools",
          link: "/pages/pages/account/billing",
        },
      ],
    };
  },
};
</script>
