import axios from 'axios';

const FactoryState = {
  namespaced: true,
  state: () => ({
    productLine: [],
    categories: [],
    devices: [],
    isLoading: false,
    errorMsg: null,
  }),
  getters: {
  },
  mutations: {
    UpdateLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    UpdateErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    UpdateDevices(state, devices) {
      state.devices = devices;
    },
  },
  actions: {
    async fetchDevices({ commit }) {
      commit('UpdateLoading', { isLoading: true });
      try {
        const { data: devicesNormal } = await this.$axios.get('api/devices?type=normal');
        const { data: devicesAdvanced } = await this.$axios.get('api/devices?type=advanced');
        const devices = [...devicesNormal, ...devicesAdvanced];
        commit('UpdateDevices', devices);
      } catch (error) {
        console.log('fetchDevices error', error);
      }
      commit('UpdateLoading', false);
    },
  }
};

export default FactoryState;
