import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL, // ya da istediğiniz bir başka URL
});

// istek interceptor'ı
instance.interceptors.request.use(
  function (config) {
    // Burada istek config'ini özelleştirebilirsiniz.
    // Örneğin, belirli bir header ekleyebilirsiniz:
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  function (error) {
    // istekle ilgili bir hata olursa burada işlenir
    return Promise.reject(error);
  }
);

// yanıt interceptor'ı
instance.interceptors.response.use(
  function (response) {
    // Herhangi bir durum kodu için yanıtı özelleştirebilirsiniz.
    // Örneğin, 200 durum kodu için özelleştirmeler yapabilirsiniz:
    if (response.status === 200) {
      // işlem yap
    }

    return response;
  },
  function (error) {
    // yanıtla ilgili bir hata olursa burada işlenir
    return Promise.reject(error);
  }
);

export default instance;
