<template></template>
<script>
import AppBarPages from "./Widgets/AppBarPages.vue";
import AppBarAuthentication from "./Widgets/AppBarAuthentication.vue";
import AppBarApplications from "./Widgets/AppBarApplications.vue";
import AppBarEcommerce from "./Widgets/AppBarEcommerce.vue";
import AppBarDocs from "./Widgets/AppBarDocs.vue";

export default {
  name: "app-bar",
  components: {
    AppBarPages,
    AppBarAuthentication,
    AppBarApplications,
    AppBarEcommerce,
    AppBarDocs,
  },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  data() {
    return {
      dialog: false,
      links: [
        {
          name: "Pages",
        },
        {
          name: "Authentication",
        },
        {
          name: "Applications",
          link: "/pages/pages/login",
        },
        {
          name: "Ecommerce",
          link: "/pages/pages/register",
        },
        {
          name: "Docs",
          link: "/pages/pages/pricing-page/lock",
        },
      ],
    };
  },
};
</script>
